import { DesignerPatternController } from "./DesignerPatternController";

//Debug class for development
class Debug{
    //Select menu for what designer preset to display
    static DIVSelectMenu(){
        let debug_template_select = document.getElementById("debug_template_select");
        if(!debug_template_select){
            return;
        }
        let debug_template_select_value = localStorage.getItem("debug_template_select");

        let templates = [
            {
                name: 'None / Default',
                value: ''
            },
            {
                name: 'Product Select',
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="" data-canvas="" data-artikel="" data-tid="120225-1648146239623cb73fc55ca" data-mode="loadmuster" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json">'
            },
            {
                name: 'Trodat 4913',
                value: '<div id="designer" data-knr="" data-kdnr="120368" data-prototyp="2453" data-canvas="120368-16487371356245bb6fa756e" data-artikel="4913" data-tid="120368-16487371356245bb6fa756e" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json">'
            },
            {
                name: 'Trodat 4912',
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="4084" data-canvas="120225-165488038662a378826e785" data-artikel="4912" data-tid="120225-165488038662a378826e785" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "New Designer Save",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="2453" data-canvas="120225-165539243362ab48b105155" data-artikel="4913" data-tid="120225-165539243362ab48b105155" data-mode="loadagain" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "New Designer Save (Filling)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="17303" data-canvas="120225-165723447062c764264bbed" data-artikel="HOLZSTEMPEL" data-tid="120225-165723447062c764264bbed" data-mode="loadagain" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
                
            },
            {
                name: "Kautbullinger FP 1223",
                value: '<div id="designer" data-knr="" data-kdnr="106104" data-prototyp="25201" data-canvas="106104-165531416562aa16f5e4586" data-artikel="FP 1223" data-tid="106104-165531416562aa16f5e4586" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: 'Kautbullinger FP 0866 (unter Gestaltungsmuster Spezialfälle)',
                value: '<div id="designer" data-knr="" data-kdnr="106104" data-prototyp="23229" data-canvas="106104-165547533962ac8c8bc4028" data-artikel="FP 0866" data-tid="106104-165547533962ac8c8bc4028" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: 'Kautbullinger 5440/4 (unter Gestaltungsmuster Spezialfälle)',
                value: '<div id="designer" data-knr="" data-kdnr="106104" data-prototyp="21120" data-canvas="106104-165547599662ac8f1c2c8bf" data-artikel="5440/4" data-tid="106104-165547599662ac8f1c2c8bf" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Date 5440",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="21120" data-canvas="120225-165489041562a39fafa656f" data-artikel="5440/4" data-tid="120225-165489041562a39fafa656f" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Date 5460",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="17642" data-canvas="120225-165522382162a8b60dc38b7" data-artikel="5460/4" data-tid="120225-165522382162a8b60dc38b7" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Date 4727",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="9614" data-canvas="120225-165901490762e28efb063fd" data-artikel="4727" data-tid="120225-165901490762e28efb063fd" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            
            {
                name: "Date 4724/2",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="19038" data-canvas="120225-1670870770639776f2e06fc" data-artikel="4724/2" data-tid="120225-1670870770639776f2e06fc" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Date 5200",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="21072" data-canvas="120225-1681751659643d7e6b230" data-artikel="5200/4" data-tid="120225-1681751659643d7e6b230" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Date 5204/4",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="17739" data-canvas="120225-1683663611645aaafb3b0" data-artikel="5204/4" data-tid="120225-1683663611645aaafb3b0" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
                
            },
            {
                name: "Date 5204/4 Debug Proto",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="27322" data-canvas="120225-1683663611645aaafb3b0" data-artikel="5204/4" data-tid="120225-1683663611645aaafb3b0" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
                
            },
            {
                name: "4921 (Mini)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="13842" data-canvas="120225-1664823819633b320bced2a" data-artikel="4921" data-tid="120225-1664823819633b320bced2a" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "S160 (Mini) Text mit Datum",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26808" data-canvas="120225-167087247063977d96a24f2" data-artikel="S160" data-tid="120225-167087247063977d96a24f2" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Holzstempel",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="17303" data-canvas="120225-1669062853637be0c58a985" data-artikel="HOLZSTEMPEL" data-tid="120225-1669062853637be0c58a985" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Stempelplatte PrinterQ30",
                value: '<div id="designer" data-kdnr="120368" data-knr="" data-prototyp="14700" data-artikel="TEXTPLATTEPRINTERQ30" data-canvas="120368-17167906146654255683c56" data-tid="120368-17167906146654255683c56" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Static Test",
                value: '<div id="designer" data-kdnr="120225" data-knr="327842" data-prototyp="" data-artikel="4931" data-canvas="10473" data-tid="120225-170929679565e1cc9b2331a" data-mode="loadmuster" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Static Geocaching",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="25634" data-canvas="119224-638745fe83616" data-artikel="GEOCACHINGSTEMPEL 13" data-tid="119224-638745fe83616" data-mode="" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Grillzange",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="20854" data-canvas="120225-1670267063638e40b78097e" data-artikel="GRILLZANGE" data-tid="120225-1670267063638e40b78097e" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "2860 Datumsstempel (/Tabelle)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="7116" data-canvas="120225-167155879063a1f686622b5" data-artikel="2860" data-tid="120225-167155879063a1f686622b5" data-mode="" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Tabellenstempel 5211",
                value: '<div id="designer" data-knr="" data-kdnr="120368" data-prototyp="6726" data-canvas="120368-170835050665d35c2a7dc9f" data-artikel="5211" data-tid="120368-170835050665d35c2a7dc9f" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Tabellenstempel MCI 54120",
                value: '<div id="designer" data-knr="" data-kdnr="120368" data-prototyp="26217" data-canvas="120368-170869558065d8a01c8642e" data-artikel="MCI54120" data-tid="120368-170869558065d8a01c8642e" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "5480/2/4 Zweifarbig",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="23550" data-canvas="120225-170852573665d608a8604" data-artikel="5480/2/4" data-tid="120225-170852573665d608a8604" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "4731 MCI (Rahmen)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26275" data-canvas="120225-170841810365d46437694" data-artikel="MCI4731" data-tid="120225-170841810365d46437694" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "4911 MCI",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26109" data-canvas="120225-169383881564f5eddf9ec" data-artikel="MCI4911" data-tid="120225-169383881564f5eddf9ec" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" >'
            },
            {
                name: "4927 MCI",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26117" data-artikel="MCI4927" data-tid="119224-656f43cacd082" data-canvas="119224-656f43cacd082" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" >'
            },
            {
                name: "MCI Datum (5480)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26215" data-artikel="MCI5480" data-tid="120368-17022867776576d5b90252d" data-canvas="120368-17022867776576d5b90252d" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" >'
            },
            {
                name: "MCI Datum verschoben (54120 )",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26218" data-artikel="MCI54120DL" data-tid="120368-17022867776576d5b90252d" data-canvas="120368-17022867776576d5b90252d" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" >'
            },
            {
                name: "MCI Datum (MCI54120)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26217" data-canvas="120225-170593261565ae7747dfd" data-artikel="MCI54120" data-tid="120225-170593261565ae7747dfd" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "4813 (Datum Seperat)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26760" data-canvas="120225-170652215765b7762d32b" data-artikel="4813" data-tid="120225-170652215765b7762d32b" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "MCI 5431 (Text gedreht)",
                value: '<div id="designer" data-knr="" data-kdnr="120368" data-prototyp="26211" data-canvas="120368-170859700065d71f081ef70" data-artikel="MCI5431" data-tid="120368-170859700065d71f081ef70" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "54126DD Doppel Datum",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="13216" data-canvas="120225-170860147665d7308421e" data-artikel="54126DD" data-tid="120225-170860147665d7308421e" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "Gestaltungsmuster MCI",
                value: '<div id="designer" data-knr="" data-kdnr="120368" data-prototyp="25552" data-canvas="120368-170841477965d4573b5038b" data-artikel="MCI5211" data-tid="120368-170841477965d4573b5038b" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" class="">'
            },
            {
                name: "4913 Strecken (loadagain)",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="2453" data-canvas="120225-1713280383661e957f5d1" data-artikel="4913" data-tid="120225-1713280383661e957f5d1" data-mode="loadagain" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json">'
            },
            {
                name: "Kreller TU Dresden (LIVE!)",
                value: '<div id="designer" data-kdnr="113389" data-knr="101925" data-prototyp="" data-artikel="FP 1111" data-canvas="3899" data-tid="113389-172367183966bd251f2fdcc" data-mode="loadmuster" data-config_url="https://www.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_frontends.json">'
            },
            {
                name: "Kreller TU Dresden Klein (LIVE!)",
                value: '<div id="designer" data-kdnr="113389" data-knr="101925" data-prototyp="" data-artikel="FP 1118" data-canvas="3906" data-tid="113389-172405305566c2f63fd5b25" data-mode="loadmuster" data-config_url="https://www.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_frontends.json">'
            }
        ];


        //Test Messe
        /*
        templates = [
            {
                name: 'Bitte auswählen (Keine Auswahl)',
                value: ''
            },
            {
                name: 'Freie Gestaltung',
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="" data-canvas="" data-artikel="" data-tid="120225-1648146239623cb73fc55ca" data-mode="loadmuster" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json">'
            },
            {
                name: 'Trodat 4913',
                value: '<div id="designer" data-knr="" data-kdnr="120368" data-prototyp="2453" data-canvas="120368-16487371356245bb6fa756e" data-artikel="4913" data-tid="120368-16487371356245bb6fa756e" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json">'
            },
            {
                name: "4927 MCI",
                value: '<div id="designer" data-knr="" data-kdnr="120225" data-prototyp="26117" data-artikel="MCI4927" data-tid="119224-656f43cacd082" data-canvas="119224-656f43cacd082" data-mode="loadcanvas" data-config_url="https://dev.stempelcloud24.com/interfaces/api_konfi_neu/API_DEV/config_DEV.json" >'
            },
        ];*/

        for(let template of templates){
            let option = document.createElement("option");
            option.value = template.value;
            option.text = template.name;
            if(option.value == debug_template_select_value){
                option.selected = true;
            }
            debug_template_select.appendChild(option);
        }

        debug_template_select.onchange = (evt) =>{
            let element = debug_template_select as HTMLSelectElement;
            localStorage.setItem("debug_template_select", element.value);
            location.reload();
        }

        if(debug_template_select_value && debug_template_select_value != ""){
            this.setDIV(debug_template_select_value);
        }

        
    }

    static TemplateLoadStringLegacy(patternController: DesignerPatternController){
        let debug_template_load_string = document.getElementById("debug_template_load_string");
        if(!debug_template_load_string){
            return;
        }

        debug_template_load_string.addEventListener("click", (evt) => {
            let input = window.prompt("Template Daten hineinkopieren");
            if(input){
                patternController.loadLegacyTemplateFromString(input);
            }
        });
    }

    static SetSVGButton(tid: string, api:string){
        let svgButton = document.getElementById("generate_svg");
        let svgPathsButton = document.getElementById("generate_svg_paths");

        if(!svgButton || !svgPathsButton){
            return;
        }

        //API path - everything until last dash
        let apiFolder = api.substring(0, api.lastIndexOf("/"));
        //Open new tab with SVG
        let link = apiFolder + "/svg_generator.php?tempTID=" + tid +"&noDownload=1";

        svgButton?.addEventListener("click", (evt) => {
            window.open(link, "_blank");
        });

        svgPathsButton?.addEventListener("click", (evt) => {
            window.open(link + "&toPaths=1", "_blank");
        });
    }

    //Set designer div to a preset
    static setDIV(div: string, overwriteConfigURL = true){
        let center = document.getElementById("designer_debug_div_center");
        if(center){
            let designer = document.getElementById("designer");
            let configURL = "";
            if(designer){
                configURL = designer.getAttribute("data-config_url") || "";
            }
            center.innerHTML = div+
                `<div id="designer_alerts"></div>
                <div id="designer_editor">
                    <div class="toolbars">
                        <div class="toolbar" id="toolbar_tabs"></div>
                        <div class="toolbar" id="toolbar_sub"></div>
                    </div>
                    <div class="main_area">
                        <div id="left_area">
                            <div id="config_area">
                                <div id="config_tabs"></div>
                                <div id="actions"></div>
                            </div>
                        </div>
                        <div class="right_area">
                            <div id="canvas"></div>
                            <div id="canvas_details"></div>
                        </div>
                    </div>
                </div>
                
                <div>
                    <button id="generate_svg">SVG Generieren</button>
                    <button id="generate_svg_paths">SVG mit Kurven Generieren</button>
                </div>
                
            </div>`;

            //Restore config URL
            let newDesigner = document.getElementById("designer");
            if(newDesigner && configURL != "" && overwriteConfigURL){
                newDesigner.setAttribute("data-config_url", configURL);
            }
        }
    }
}

export {Debug}