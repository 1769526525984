import { DesignerController } from "./DesignerController";
import { DesignerDate, DesignerImage } from "./DesignerElements";
import { Bounds, RenderMode } from "./TypescriptInterfaces";

//Class for rendering content in the canvas
class DesignerRenderer{
    ctx: CanvasRenderingContext2D;
    canvas: HTMLCanvasElement;
    controller: DesignerController;

    constructor(ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement, controller: DesignerController){
        this.ctx = ctx;
        this.canvas = canvas;
        this.controller = controller;
    }

    //Render canvas, automatic
    render(){
        let ctx = this.ctx;

        let canvas = this.canvas;
        let bounds = this.controller.calcBounds(canvas);
        
        let xend = canvas.width-this.controller.config.rulerSpaceEnd;
        let yend = canvas.height-this.controller.config.rulerSpaceEnd;

        ctx.fillStyle = "#000000";
        //Clear previous frame
        ctx.globalAlpha = 1;
        ctx.clearRect(0,0,canvas.width, canvas.height);

        //Draw grey background for edit area
        ctx.fillStyle = "#dddddd";
        ctx.fillRect(this.controller.config.rulerX,this.controller.config.rulerY,xend-this.controller.config.rulerX,yend-this.controller.config.rulerY);

        //Clear Area that has content
        ctx.fillStyle = "white";
        //ctx.clearRect(bounds.minX-1,bounds.minY-1,bounds.w+2,bounds.h+2);
        ctx.fillRect(bounds.minX-1,bounds.minY-1,bounds.w+2,bounds.h+2);
        //ctx.clearRect(this.controller.config.rulerX,this.controller.config.rulerY,xend-this.controller.config.rulerX,yend-this.controller.config.rulerY);


        ctx.globalAlpha = 1;
        ctx.fillStyle = "black";
        //Render Elements
        for(let element of this.controller.elements){
            //Render date and filling last
            if(element instanceof DesignerDate){
                continue;
            }
            if(element instanceof DesignerImage && element.filling){
                continue;
            }
            element.render(ctx);
        }

        //Render grid
        if(this.controller.showGrid){
            ctx.globalAlpha = 0.25;
            ctx.strokeStyle = "#333333";
            ctx.lineWidth = 1;
            ctx.beginPath();
            for(let x = this.controller.config.rulerX;x <= xend;x += this.controller.gridSpaceing*this.controller.config.pixelToMM){
                ctx.moveTo(x,this.controller.config.rulerY);
                ctx.lineTo(x,yend);
            }
            for(let y = this.controller.config.rulerY;y <= yend;y += this.controller.gridSpaceing*this.controller.config.pixelToMM){
                ctx.moveTo(this.controller.config.rulerX,y);
                ctx.lineTo(xend,y);
            }
            ctx.stroke();
            ctx.closePath();
        }

        ctx.globalAlpha = 1;
        ctx.fillStyle = "black";
        ctx.strokeStyle = "black";

        //BORDER
        this.renderBorder(ctx, bounds);

        //Render filling image
        for(let element of this.controller.elements){
            if(element instanceof DesignerImage){
                if(element.filling){
                    /*let area = this.editAreaSize();
                    element.x = area.minX;
                    element.y = area.minY;
                    element.w = area.w;
                    element.h = area.h;*/
                    element.render(ctx);
                }
            }
        }

        //Render date field
        for(let element of this.controller.elements){
            if(element instanceof DesignerDate){
                element.render(ctx);
            }
        }

        //UI

        //Clear overlapping elements under UI
        //Top
        ctx.clearRect(0,0,canvas.width, this.controller.config.rulerY);
        //Left
        ctx.clearRect(0,0,this.controller.config.rulerX, canvas.height);
        //Right
        ctx.clearRect(canvas.width,0,-this.controller.config.rulerSpaceEnd,canvas.height);
        //Bottom
        ctx.clearRect(0,canvas.height,canvas.width,-this.controller.config.rulerSpaceEnd);

        ctx.globalAlpha = 1;
        ctx.fillStyle = "black";
        ctx.strokeStyle = "black";

        this.drawRuler();

        if(this.controller.type == "print"){
            this.drawPrintLines(bounds);
        }

        //Render element UI
        ctx.globalAlpha = 1;
        ctx.fillStyle = "black";
        for(let element of this.controller.elements){
            if(this.controller.input.elementSelected == element){
                element.renderSelected(ctx);
                if(element instanceof DesignerImage){
                    element.renderAnchors(ctx);
                }
            }
        }

        //Render Element error outlines over UI
        ctx.globalAlpha = 1;
        for(let element of this.controller.elements){
            if(element.error){
                ctx.globalAlpha = 1;
                element.renderError(ctx);
                ctx.globalAlpha = 1;
                element.renderWarningIcon(ctx);
            }
        }

        //DEBUG
        ctx.fillStyle = "white";
        ctx.globalAlpha = 0;
        let error = 0;
        ctx.fillRect(bounds.minX-error,bounds.minY-error,bounds.w+error*2,bounds.h+error*2);
        ctx.globalAlpha = 1;
        ctx.fillStyle = "black";

        window.requestAnimationFrame(() => this.render());
    }

    //Render canvas upscaled for PDF and production
    //preview = greyscale
    renderUpscale(upscaleCanvas: HTMLCanvasElement, scaleFactor: number, renderMode: RenderMode){
        let canvas = upscaleCanvas;
        let ctx = upscaleCanvas.getContext('2d');
        if(!ctx){
            console.error('Couldnt get canvas context');
            return;
        }

        //Disable smoothing for no aliasing for laser
        ctx.imageSmoothingEnabled = false;
        canvas.style.shapeRendering = "crispEdges";


        ctx.globalAlpha = 1;
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0,0,canvas.width, canvas.height);

        let bounds = this.controller.calcBounds(canvas, true);
        
        //Render Elements
        for(let element of this.controller.elements){
            ctx.globalAlpha = 1;
            //For production PDF everything greyscale (except MCI)
            if(renderMode == RenderMode.Production ){
                ctx.fillStyle = "black";
                ctx.strokeStyle = "black";
            }
            //Render date and filling elements last
            if(element instanceof DesignerDate){
                continue;
            }
            if(element instanceof DesignerImage){
                if(element.filling){
                    continue;
                }
            }
            element.render(ctx, renderMode);
        }

        ctx.globalAlpha = 1;
        //For production PDF everything greyscale (except MCI)
        if(renderMode == RenderMode.Production ){
            ctx.fillStyle = "black";
            ctx.strokeStyle = "black";
        }

        //BORDER
        this.renderBorder(ctx, bounds, renderMode);

        //Render filling image
        for(let element of this.controller.elements){
            if(element instanceof DesignerImage){
                if(element.filling){
                    element.render(ctx, renderMode);
                }
            }
        }

        //Render date field
        for(let element of this.controller.elements){
            if(element instanceof DesignerDate){
                element.render(ctx, renderMode);
            }
        }

        return bounds;
    }

    renderBorder(ctx: CanvasRenderingContext2D, bounds: Bounds, renderMode:RenderMode = RenderMode.Designer){
        if(this.controller.borderShows() && this.controller.elements.length > 0){
            ctx.globalAlpha = 1;

            //Set color
            ctx.strokeStyle = "black";
            if(renderMode != RenderMode.Production){
                ctx.strokeStyle = this.controller.inkpadColor;
            }
            if(this.controller.MCI && this.controller.border.color){
                ctx.strokeStyle = this.controller.border.color;
            }
            
            ctx.lineWidth = Math.ceil(this.controller.border.width*this.controller.config.pixelToMM / 2) * 2;
            let bw = Math.floor(ctx.lineWidth/2);

            ctx.strokeRect(
                Math.floor(bounds.minX+bw),
                Math.floor(bounds.minY+bw),
                Math.ceil(bounds.w-bw*2),
                Math.ceil(bounds.h-bw*2)
            );

            //Doppelter Rahmen
            if(this.controller.border.double == true){
                //1mm + 150% of the border width
                let dist = (this.controller.config.pixelToMM)+(bw*3);
                ctx.strokeRect(
                    Math.floor(bounds.minX+(dist) ),
                    Math.floor(bounds.minY+(dist) ),
                    Math.ceil(bounds.w-(dist)*2 ),
                    Math.ceil(bounds.h-(dist)*2) 
                );
            }
            ctx.lineWidth = 1;
        }
    }

    //Draw the ruler for scale
    drawRuler(){
        this.ctx.fillStyle = "black";
        this.ctx.strokeStyle = "black";
        this.ctx.globalAlpha = 1;
        this.ctx.lineWidth = 1;
        this.ctx.setLineDash([]);
        let dimensions = this.controller.editAreaSize();

        let rulerSize = 2;
        
        this.ctx.fillRect(this.controller.config.rulerX - rulerSize,this.controller.config.rulerY - rulerSize, dimensions.w + rulerSize, rulerSize);
        this.ctx.fillRect(this.controller.config.rulerX - rulerSize,this.controller.config.rulerY - rulerSize, rulerSize ,dimensions.h + rulerSize);

        this.ctx.beginPath();
        /*
        this.ctx.moveTo(this.controller.config.rulerX,this.controller.config.rulerY);
        this.ctx.lineTo(this.controller.config.rulerX+dimensions.w,this.controller.config.rulerY);

        this.ctx.moveTo(this.controller.config.rulerX,this.controller.config.rulerY);
        this.ctx.lineTo(this.controller.config.rulerX,this.controller.config.rulerY+dimensions.h);
        */

        this.ctx.font = "11px Arial";

        let wmm = dimensions.w/this.controller.config.pixelToMM;
        let hmm = dimensions.h/this.controller.config.pixelToMM;

        for(let i = 0;i <= wmm;i ++){
            let lineLength = 4;
            if(i%5 == 0){
                lineLength = 6;
            }
            if(i%10 == 0){
                lineLength = 8;
            }
            lineLength += 0.5;
            let pos = this.controller.config.rulerX+i*( dimensions.w / wmm);
            //this.ctx.moveTo(pos,this.controller.config.rulerY);
            //this.ctx.lineTo(pos,this.controller.config.rulerY-lineLength);
            this.ctx.fillRect(pos, this.controller.config.rulerY - lineLength, 1 , lineLength);

            if(i%10 == 0){
                let strWidth = this.ctx.measureText(i.toString()).width;
                this.ctx.fillText(i.toString(),pos-strWidth/2,10);
            }
            
        }

        for(let i = 0;i <= hmm;i ++){
            let lineLength = 4;
            if(i%5 == 0){
                lineLength = 6;
            }
            if(i%10 == 0){
                lineLength = 10;
            }
            lineLength += 0.5;
            let pos = this.controller.config.rulerY+i*( dimensions.h / hmm);
            //this.ctx.moveTo(this.controller.config.rulerX,pos);
            //this.ctx.lineTo(this.controller.config.rulerX-lineLength,pos);
            this.ctx.fillRect(this.controller.config.rulerX - lineLength, pos, lineLength, 1);

            if(i%10 == 0){
                this.ctx.fillText(i.toString(),0,pos+4);
            }
        }
        this.ctx.closePath();
        this.ctx.stroke();
    }

    //Outlines for prints
    drawPrintLines(bounds: Bounds){
        //Only draw lines if they fit into the area
        if(bounds.w < this.controller.config.securityLineDistance*3 || bounds.h < this.controller.config.securityLineDistance*3){
            return;
        }
        this.ctx.setLineDash([5,5]);
        this.ctx.globalAlpha = 0.66;
        this.ctx.strokeStyle = "red";
        this.ctx.lineWidth = 2.5;
        this.ctx.strokeRect(bounds.minX,bounds.minY,bounds.w,bounds.h);

        this.ctx.strokeStyle = "#666666";
        this.ctx.lineWidth = 1;
        this.ctx.strokeRect(bounds.minX+this.controller.config.securityLineDistance,bounds.minY+this.controller.config.securityLineDistance,bounds.w-this.controller.config.securityLineDistance*2,bounds.h-this.controller.config.securityLineDistance*2);
        this.ctx.setLineDash([]);
        this.ctx.strokeStyle = "black";
        this.ctx.globalAlpha = 1;
    }
}

export { DesignerRenderer };