import { DesignerText, DesignerDate, DesignerImage, DesignerRect, DesignerElement } from "./DesignerElements";

interface Bounds{
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    w: number;
    h: number;
    noBorder: {
        'minX': number,
        'maxX': number,
        'minY': number,
        'maxY': number,
        'w': number,
        'h': number
    }
}

interface VKData{
    url: string | null;
    urlEdit: string | null;
    firma: string | null;
    slogan: string | null;
    name: string | null;
    strasse: string | null;
    plz: string | null;
    ort: string | null;
    telefon: string | null;
    telefax: string | null;
    mobil: string | null;
    email: string | null;
    web: string | null;
    [key: string]: string | null;
}

interface Border{
    active: boolean;
    padding: number;
    width: number;
    double: boolean;
    style: string; //"solid" | "short" | "med" | "long"
    color?: string;
}

interface BorderLegacy{
    width: number;
    space: number;
    double: number;
    color: string;
}

interface LineProperties{
    width: number;
    length: number;
    orientation: RectOrientation
}

interface APIResponse{
    responseCode: 'success' | 'error';
    data: string;
}

interface SendImageResponse{
    message: string;
    path: string;
    width: number;
    height: number;
    info: string;
}

interface SendImageVKResponse{
    savedResult: boolean;
    error: null | string;
    image: string;
}

interface SendFileResponse{
    message: string;
    path: string;
    width: number;
    height: number;
    info: string;
    width_mm: number,
    height_mm: number;
    w?: number;
    h?: number;
    needsScaling: boolean;
}

interface SendImageError{
    message: string;
    error: string;
}

interface MCISpacingError{
    element: DesignerElement;
    otherElement: DesignerElement | null;
    distX: number;
    distY: number;
}

interface UndoObject{
    saveDataJSON: string;
}

interface Tabs{
    text: string;
    pattern: string;
    userpattern: string;
    border: string;
    image: string;
    full: string;
    full_text: string;
    full_text_mci: string;
    qr: string;
    qr_vk: string;
    qr_gen: string;
}

interface Clipart{
    path: string;
    width: string;
    height: string;
    datei: string;
}

interface Product{
    artikel_nr: string;
    name: string;
    bild: string;
    url: string;
    price: string;
    width: number;
    height: number;
}

interface FontObject{
    name: string;
    style: {
        normal: string;
        fett?: string;
        kursiv?: string;
        fettkursiv?: string;
        schmal?: string;
    };
}

interface ColorObject{
    name:string;
    hex: string;
}

interface CanvasTemplate{
    type: string,
    round: string,
    importer: string,
    grid: string,
    drilling: string | null,
    autodrilling: string | null,
    designtemplate: string | null,
    name: string,
    layout_x: string,
    layout_y: string,
    layout_w: string,
    layout_h: string,
    canvas: string,
    border: string | null,
    products: string,
    ShowPrice: string
}

interface TemplateObject{
    data: string;
    border: any;
    pad?: string;
}

interface UserTemplateObject{
    data : {
        border: string;
        canvas: string;
        dimension: string;
        pad: string;
    }
}

interface Dater{
    width: number;
    height: number;
    x: number;
    y: number;
    content: string;
    "font-size": number;
    font: string;
}

interface Metadata{
    formatVersion: string;
    offset: {
        x: number,
        y: number
    }
    dimension:{
        w: number;
        h: number;
    }
}

interface Box{
    x: number;
    y: number;
    w: number;
    h: number;
}

interface SaveDataElement{
    x: number;
    y: number;
    w?: number;
    h?: number;
    type: string;
    patternID: string;
    align: Alignment;
    blockedActions: BlockedActions[];
    static: boolean;
    color: string | null;
    rotation: number;
}

interface SaveDataText extends SaveDataElement{
    text: string;
    size: number;
    font: string;
    bold: boolean;
    italic: boolean;
    underline: boolean;
    stretch: number;
    h: number;
    baselineHeight: number;
}

interface SaveDataDate extends SaveDataElement{
    w: number;
    h: number;
    text: string;
    size: number;
    font: string;
}


interface SaveDataImage extends SaveDataElement{
    url: string;
    w: number;
    h: number;
    greyscale: boolean;
    filling: boolean;
}


interface SaveDataRect extends SaveDataElement{
    w: number;
    h: number;
    color: string;
    orientation: "horizontal"|"vertical";
}

interface LegacySaveDataElement {
    x: number;
    y: number;
    type: string;
    width: number;
    height: number;
    fontSize: number;
    id: string;
    dataAlign: string;
    dataFont: string;
    dataUnderline: string;
    dataStyle: "" | "bold" | "italic" | "bolditalic";
    dataStretch: number;
    content: string;
    href: string;
    dataLink?: string;
    fill: string;
    dataOrientation: RectOrientation;
    dataRights?: string;
    dataColor?: string;
    dataRotate?: string;
}

interface SaveObject{
    elements: SaveDataTypes[];
    border: Border|null;
    pad: string;
    metadata: Metadata;
}

interface LocalStorageDimension{
    w: number;
    h: number;
    maxW: number;
    maxH: number;
}

interface TextProperties{
    size: number,
    font: string,
    bold: boolean,
    italic: boolean,
    underline: boolean
}

interface Dataset {
    kdnr: string;
    tid: string;
    mode: string;
    knr: string;
    prototyp: string;
    canvas: string;
    artikel: string;
}

interface BuyButtonConfig{
    buttonID: string;
    buttonAddClass: string;
    buttonRemoveClass: string;
}

interface PriceInfoConfig{
    realtimeUpdates: boolean;
    appendString: string;
    VK_ID: string;
    EK_ID: string;
    VK_NETTO_ID: string;
    VCode_ID: string;
}

interface ShopInterfaceConfig{
    buyButton: BuyButtonConfig,
    priceInfo: PriceInfoConfig
}

interface Config{
    metadata: Metadata,
    dataset: Dataset,
    shopInterface: ShopInterfaceConfig,
    typ: string,
    userCode: string,
    userID: string,
    pixelToMM: number,
    pixelToMMUpload: number,
    automaticScaling: boolean,
    scalingTargetWidth: number,
    clipartScaling: number,
    minWidthMM: number,
    minHeightMM: number,
    widthMM: number,
    heightMM: number,
    maxWidthMM: number,
    maxHeightMM: number,
    imageSpawnMinWidth: number,
    imageSpawnMinHeight: number,
    imageSpawnMaxWidth: number,
    imageSpawnMaxHeight: number,
    imageaAchorResizeSize: number,
    QRMinWidth: number,
    QRMinHeight: number,
    textMinWidth: number,
    selectMinWidth: number,
    selectMinHeight:number,
    rulerX: number,
    rulerY: number,
    rulerSpaceEnd: number,
    rulerPadding: number,
    fontMinSize: number,
    fontMaxSize: number,
    fontIncrement: number,
    borderMinWidth: number,
    borderMaxWidth: number,
    borderSpacingStart: number,
    lineMinWidth: number,
    lineMaxWidth: number,
    securityLineDistance: number,
    historyLength: number,
    hideNotificationTimer: number,
    API: string,
    APILegacy: string,
    APIKey: string,
    imagePath:string,
    PDFPreviewPath: string,
    fontPath: string,
    clipartPath: string,
    showUpscaleCanvasDebug: boolean,
    init: any,
    userPattern : {
        allowed: boolean,
        delete: boolean,
        change: boolean,
        create: boolean,
        random: string
    }
}

enum RenderMode{
    Designer,
    Preview,
    Production,
    ProductionMCI
};

type BlockedActions = "move" | "edit" | "list" | "select" | "remove" | "scale" | "changeText" | "changeFont" | "changeAlignment" | "changeStretch" | "underline";

type RectOrientation = "horizontal" | "vertical";

type DesignerType = "stamp" | "print";

type Alignment = "centerH" | "left" | "right" | "centerV" | "top" | "bottom" | null;

type TextSpecials = "" | "bold" | "italic" | "underline";

type ActiveTabs = "text" | "pattern"| "userpattern" | "image" | "border" | "full" | "qr";

type ElementTypes = DesignerText | DesignerDate | DesignerRect | DesignerImage;

type SaveDataTypes = SaveDataText | SaveDataDate | SaveDataRect | SaveDataImage;

type ColorSet = {
    [key:string]: ColorObject;
}

//Export all interfaces
export {
    Bounds,
    Border,
    BorderLegacy,
    LineProperties,
    APIResponse,
    SendImageResponse,
    SendImageVKResponse,
    SendFileResponse,
    SendImageError,
    MCISpacingError,
    UndoObject,
    Tabs,
    Clipart,
    Product,
    FontObject,
    ColorObject,
    CanvasTemplate,
    TemplateObject,
    UserTemplateObject,
    Dater,
    Metadata,
    Box,
    SaveDataElement,
    SaveDataText,
    SaveDataDate,
    SaveDataImage,
    SaveDataRect,
    LegacySaveDataElement,
    SaveObject,
    LocalStorageDimension,
    TextProperties,
    Dataset,
    BuyButtonConfig,
    PriceInfoConfig,
    ShopInterfaceConfig,
    Config,
    RenderMode,
    BlockedActions,
    RectOrientation,
    DesignerType,
    Alignment,
    TextSpecials,
    ActiveTabs,
    ElementTypes,
    SaveDataTypes,
    ColorSet,
    VKData
}