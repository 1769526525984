import { DesignerController } from "./DesignerController";
import { APIResponse, Tabs } from "./TypescriptInterfaces";
import { DesignData } from "./Utility";

class API{
    urlLegacy: string;
    key: string;
    k_kdnr: string; //debtor number
    knr: string; //Reseller

    //caching
    patternCache = {text: null, design: null, user:null, global:null};
    clipartCache:any = null;

    constructor(urlLegacy: string, key: string, k_kdnr: string, knr: string){
        this.urlLegacy = urlLegacy;
        this.key = key;
        this.k_kdnr = k_kdnr;
        this.knr = knr;
    }

    //Generic request method for legacy API
    async request(action: string, bonusParams: any = null, contentType: string|null = null): Promise<any>{
        let _this = this;
        return new Promise(function (resolve, reject) {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = async function() {
                if(xmlHttp.readyState == 4 && xmlHttp.status == 200){
                    if(!xmlHttp.responseText){
                        reject("ERROR: Empty API response");
                    }
                    try{
                        let response = JSON.parse(xmlHttp.responseText);
                        resolve(response);
                    }catch(error){
                        reject("Couldn't parse JSON: ");
                        console.error(error);
                    }
                }
            }

            xmlHttp.onerror = function(){
                reject("NETWORK ERROR! Can't reach API.\n"
                +"Bad Server Response: Status:"+xmlHttp.status+" , State:"+xmlHttp.readyState);
            }

            xmlHttp.open("POST", _this.urlLegacy, true);
            if(contentType == null){
                xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            }else{
                xmlHttp.setRequestHeader('Content-Type', contentType);
            }
            
            let params:any = {};
            params.key = _this.key;
            params.k_kdnr = _this.k_kdnr;
            if(_this.knr != ''){
                params.knr = _this.knr;
            }
            
            //Special case for API
            if(bonusParams != null && bonusParams.mode == "loadmuster" && _this.knr == ''){
                params.knr = 0;
            }
            
            params.action = action;
            if(bonusParams !== null){
                for(let key in bonusParams){
                    let value = bonusParams[key];
                    params[key] = value;
                }
            }
            let urlParams = new URLSearchParams(params).toString();
            xmlHttp.send(urlParams);
        });
    }

    //Uploading a image via legacy API
    async imageRequest(action: string, bonusParams: any = null, imageBinary: File): Promise<any>{
        let _this = this;
        return new Promise(function (resolve, reject) {
            var xmlHttp = new XMLHttpRequest();

            xmlHttp.onreadystatechange = async function() {
                if(xmlHttp.readyState == 4 && xmlHttp.status == 200){
                    if(!xmlHttp.responseText){
                        reject("ERROR: Empty API response");
                    }
                    try{
                        let response = JSON.parse(xmlHttp.responseText);
                        resolve(response);
                    }catch(error){
                        reject("Couldn't parse JSON: ");
                        console.error(error);
                    }
                }
            }
            xmlHttp.onerror = function(){
                reject("NETWORK ERROR! Can't reach API.\n"
                +"Bad Server Response: Status:"+xmlHttp.status+" , State:"+xmlHttp.readyState);
            }
            xmlHttp.open("POST", _this.urlLegacy, true);
            //xmlHttp.setRequestHeader('Content-Type', 'multipart/form-data');
            
            var formData = new FormData();
            formData.append("key", _this.key);
            formData.append("k_kdnr", _this.k_kdnr);
            
            //Special exception
            if(bonusParams != null && bonusParams.mode == "loadmuster" && _this.knr == ''){
                formData.append("knr", "0");
            }else{
                if(_this.knr != ''){
                    formData.append("knr", _this.knr);
                }
            }
            
            formData.append("action", action);
            if(bonusParams !== null){
                for(let key in bonusParams){
                    let value = bonusParams[key];
                    formData.append(key, value);
                }
            }
            
            formData.append("file", imageBinary);
            
            let upload = document.getElementById('image_upload');
            let bar = _this.createProgressBar();
            upload?.parentElement?.appendChild(bar);
    
            xmlHttp.upload.onprogress = function(event: ProgressEvent){
                if (event.lengthComputable) {
                    let percentage = Math.round((event.loaded * 100) / event.total);
                    _this.updateProgressBar(bar, percentage);
                }
            }

            xmlHttp.upload.onload = function(){
                _this.deleteProgressBar(bar);
            }
            xmlHttp.send(formData);
        });
    }

    //Instatiate progress bar for upload
    createProgressBar(): HTMLDivElement{
        let progress = document.createElement('div');
        progress.classList.add("progress");
        progress.innerHTML = '<div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%">0%</div>';
        let bar = progress.getElementsByClassName('progress-bar')[0] as HTMLDivElement;
        return bar;
    }

    //Upodate progress bar for upload
    updateProgressBar(bar: HTMLDivElement, value: number){
        bar.style.width = value+"%";
        bar.innerText = value+"%";
        bar.ariaValueNow = value.toString();
    }

    //Delete progress bar for upload
    deleteProgressBar(bar: HTMLDivElement){
        this.updateProgressBar(bar, 100);
        setTimeout( () => {
            bar.remove();
        }, 1000);
        
    }

    //Request design pattern (Gestaltungsmuster) for this product from API
    requestDesignPatterns(artikel_nr: number | string): any{
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams:any = {};
            if(artikel_nr != null && artikel_nr != ""){
                bonusParams.artikel_nr = artikel_nr;
            }else{
                bonusParams.artikel_nr = 0;
            }
            if(_this.patternCache.design != null){
                resolve(_this.patternCache.design);
                return;
            }
            _this.request("loaddesignpattern", bonusParams).then(
                //loaddesignpattern returns object, NOT ARRAY!
                (data:any) => {
                    if(!data){
                        reject("Empty Server Response");
                    }
                    _this.patternCache.design = data;
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    //Request text pattern (Textmuster) for this product from API
    requestTextPatterns(artikel_nr: number | string): Promise<any>{
        let _this = this;
        return new Promise(function (resolve, reject) {

            let bonusParams:any = {};
            if(artikel_nr != null && artikel_nr != ""){
                bonusParams.artikel_nr = artikel_nr;
            }else{
                bonusParams.artikel_nr = 0;
            }
           
            if(_this.patternCache.text != null){
                resolve(_this.patternCache.text);
                return;
            }
            _this.request("loadpattern", bonusParams).then(
                //loadpattern returns object, NOT ARRAY!
                (data:any) => {
                    if(!data){
                        reject("Empty Server Response");
                        return;
                    }
                    _this.patternCache.text = data;
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    //Request global pattern (Globale Muster / neue Gestaltungsmuster) for this product from API
    requestGlobalPatterns(artikel_nr: number | string): Promise<any>{
        let _this = this;
        return new Promise(function (resolve, reject) {

            let bonusParams:any = {};
            if(artikel_nr != null && artikel_nr != ""){
                bonusParams.artikel_nr = artikel_nr;
            }else{
                bonusParams.artikel_nr = 0;
            }
           
            if(_this.patternCache.global != null){
                resolve(_this.patternCache.global);
                return;
            }
            _this.request("loadsample", bonusParams).then(
                //returns object, NOT ARRAY!
                (data:any) => {
                    if(!data){
                        reject("Empty Server Response");
                        return;
                    }
                    _this.patternCache.global = data;
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    /*
    key: 15c5f6599ddac784a84397a74e9248a6
    action: loadUserPattern
    artikel_nr: 4913
    k_kdnr: 120368
    k_knr: 0
    userCode: 2FG5WR
    */

    //Request user pattern (Meine Muster) for this product from API
    requestUserPatterns(artikel_nr: number | string, userCode: string, useCache = true): any{
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams:any = {};
            if(artikel_nr != null && artikel_nr != ""){
                bonusParams.artikel_nr = artikel_nr;
            }else{
                bonusParams.artikel_nr = 0;
            }

            bonusParams.userCode = userCode;
           
            if(useCache && _this.patternCache.user != null){
                resolve(_this.patternCache.user);
                return;
            }
            _this.request("loadUserPattern", bonusParams).then(
                //loadUserPattern returns object, NOT ARRAY!
                (data:any) => {
                    if(!data || data instanceof Array){
                        reject("Empty Server Response");
                        return;
                    }
                    if(useCache){
                        _this.patternCache.user = data;
                    }
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    //Delete user pattern (Meine Muster) for this product with API
    deleteUserPattern(artikel_nr: number | string, userCode: string, ident: number): any{
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams:any = {};
            if(artikel_nr != null && artikel_nr != ""){
                bonusParams.artikel_nr = artikel_nr;
            }else{
                bonusParams.artikel_nr = 0;
            }

            bonusParams.userCode = userCode;
            bonusParams.ident = ident;

            _this.request("deleteUserPattern", bonusParams).then(
                //loadUserPattern returns object, NOT ARRAY!
                (data:any) => {
                    if(!data || data instanceof Array){
                        reject("Empty Server Response");
                        return;
                    }

                    //Refresh cache
                    _this.patternCache.user = null;
                    _this.requestUserPatterns(artikel_nr,userCode,true);

                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    /*
        key: 15c5f6599ddac784a84397a74e9248a6
        action: saveUserPattern
        artikel_nr: 4913
        k_kdnr: 120368
        k_knr: 0
        tid: undefined
        userCode: 2FG5WR
        name: TestMuster
        data:
    */

    //Upload user pattern (Meine Muster) to API (also create data entry and thumbnail)
    uploadUserPattern(artikel_nr: number | string, userCode: string, tid: string, name: string, designData: DesignData, imageData: string, pixelToMM: number, MCI: boolean){
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams:any = {};
            if(artikel_nr != null && artikel_nr != ""){
                bonusParams.artikel_nr = artikel_nr;
            }else{
                bonusParams.artikel_nr = 0;
            }

            bonusParams.newDesigner = true;
            bonusParams.tid = tid;
            bonusParams.userCode = userCode;
            bonusParams.name = name;
            bonusParams.data = JSON.stringify(designData);
            bonusParams.MCI = MCI;

            //Generating preview & thumbnail
            bonusParams.pixelToMM = pixelToMM,
            bonusParams.imageData = imageData;

            _this.request("saveUserPattern", bonusParams).then(
                //saveUserPattern returns object, NOT ARRAY!
                (data:any) => {
                    if(!data ){
                        reject("Empty Server Response");
                    }

                    //Refresh cache
                    _this.patternCache.user = null;
                    _this.requestUserPatterns(artikel_nr,userCode,true);

                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    //Request available cliparts from legacy API
    requestClipart(): any{
        let _this = this;
        return new Promise(function (resolve, reject) {
            if(_this.clipartCache != null){
                resolve(_this.clipartCache);
                return;
            }
            _this.request("loadclipart").then(
                (data:any) => {
                    if(!data){
                        reject("Empty Server Response");
                    }
                    _this.clipartCache = data;
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    //Save the rendered (recolored) image to the server in case of SVG export
    saveRenderedImage(controller : DesignerController, imageData: string, color: string, url: string){
        let _this = this;
        return new Promise(function (resolve, reject) {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = function() {
                if(xmlHttp.readyState == 4 && xmlHttp.status == 200){
                    if(!xmlHttp.responseText){
                        reject("ERROR: Empty API response");
                        return;
                    }
                    //Check metadata
                    let json:APIResponse = JSON.parse(xmlHttp.responseText);
                    if(json.responseCode != 'success'){
                        console.error(json.responseCode);
                        return;
                    }
                    resolve(true);
                }
            }
            xmlHttp.onerror = function(){
                reject("NETWORK ERROR! Can't reach API");
            }
            xmlHttp.open("POST", controller.config.API, true);
            xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xmlHttp.send("request_type=saveRenderedImage&userID="+controller.config.userID+"&imageData="+imageData+"&color="+color+"&url="+url);
        });
    }

    //Get current price data
    requestPrice(width: number, height: number, typ: string, zeilen: number){
        let _this = this;
        return new Promise(function (resolve, reject) {
            let bonusParams:any = {};

            bonusParams.height = height;
            bonusParams.width = width;
            bonusParams.typ = typ;
            bonusParams.zeilen = zeilen;
           
            _this.request("loadprice", bonusParams).then(
                //loadUserPattern returns object, NOT ARRAY!
                (data:any) => {
                    if(!data || data instanceof Array){
                        reject("Empty Server Response");
                        return;
                    }
                    resolve(data);
                }
            ,(error) => {
                reject(error);
            });
        });
    }

    //Load specific font from server
    async requestFont(name:string, url:string, css:FontFaceDescriptors = {}){
        let newFont = new FontFace(name, url, css);
        await newFont.load().then(
            (loadedFont) => {
                document.fonts.add(loadedFont);
            },
            (error) => {
                console.error('Failed to load font: ' + error)
            }
        );
    }

    async GenerateVK(form: FormData){
        form.append("key", this.key);
        form.append("action", "createqr_vk");

        let response = await fetch(this.urlLegacy, {
            method: 'POST',
            body: form
        });

        return response.json();
    }

    async GenerateQRCode(form: FormData){
        form.append("key", this.key);
        form.append("action", "createqr");

        let response = await fetch(this.urlLegacy, {
            method: 'POST',
            body: form
        });

        return response.json();
    }

    //Load Template contents from API
    async loadHTMLContent(controller: DesignerController){
        let _this = this;
        return new Promise(function (resolve, reject) {
            var xmlHttp = new XMLHttpRequest();
            xmlHttp.onreadystatechange = function() {
                if(xmlHttp.readyState == 4 && xmlHttp.status == 200){
                    if(!xmlHttp.responseText){
                        reject("ERROR: Empty API response");
                        return;
                    }
                    //Check metadata
                    let json:APIResponse = JSON.parse(xmlHttp.responseText);
                    if(json.responseCode != 'success'){
                        console.error(json.responseCode);
                        return;
                    }
                    //Parse the data
                    let data:Array< string|Tabs > = JSON.parse(json.data);
                    controller.HTMLData = data;
                    for (let key in data) {
                        let val = data[key];
                        //If it's a tab, add it to the tabs
                        if(key == 'tabs'){
                            controller.tabInterface.tabsHTML = val as Tabs;
                            continue;
                        }
                        //If it's an element ID, set it's HTML
                        let element = document.getElementById(key);
                        if(element){
                            element.innerHTML = val as string;
                            continue;
                        }
                        
                        if(key == "loadingCircle"){
                            controller.designerDiv.insertAdjacentHTML("beforeend", val as string);
                        }
                    }
                    resolve(true);
                }
            }
            xmlHttp.onerror = function(){
                reject("NETWORK ERROR! Can't reach API");
            }
            xmlHttp.open("POST", controller.config.API, true);
            xmlHttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
            xmlHttp.send("request_type=requestHTMLContent&userID="+controller.config.userID);
            
        });
    }
}

export {API};